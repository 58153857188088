import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"events",
        "id":"event_id",
        "subidname":"event_club",
        "options":["edit","insert"],
        "extraoptions":[
            {
                "name":"Djs",
                "page":"/djs",
            },
        ],
        "nameSingle":"event",
        "nameMultiple":"events",
        "filters":
            [
                {
                    "key":"active",
                    "name":"State",
                    "options":{
                        "active":"Active",
                        "inactive":"Inactive"
                    }
                }
            ]
        ,
        "fields":{
            "field1":{
                "name":"Internal name",
                "field":"event_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field1b":{
                "name":"Club name",
                "field":"event_club",
                "type":"TextInput",
                "required":true,
                "list":false,
            },
            "field1c":{
                "name":"Description English",
                "field":"English",
                "type":"TextArea",
                "required":true,
                "list":false,
            },
            "field1d":{
                "name":"Description German",
                "field":"German",
                "type":"TextArea",
                "required":false,
                "list":false,
            },
            "field1e":{
                "name":"Image",
                "field":"event_image",
                "type":"ImageUpload",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Start",
                "field":"event_start",
                "type":"DateTimeInput",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"End",
                "field":"event_stop",
                "type":"DateTimeInput",
                "required":true,
                "list":false
            },
            "field5":{
                "name":"Headliner",
                "field":"event_headliner",
                "type":"TextInput",
                "required":true,
            },
            "field6":{
                "name":"Audio URL",
                "field":"event_audio",
                "type":"TextInput",
            },
            "field7":{
                "name":"Mollie apikey",
                "field":"event_apikey",
                "type":"TextInput",
            },
            "field8":{
                "name":"Active",
                "field":"event_active",
                "type":"DropDown",
                "options": {
                    "0":"inactive",
                    "1":"active",
                },
                "required":true,

            },            
            "field9":{
                "name":"Max money",
                "field":"event_money_max",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
            },     
            "field10":{
                "name":"Start amount",
                "field":"event_startamount",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
            },     
            "field11":{
                "name":"Total steps",
                "field":"event_steps",
                "type":"Display",
                "list":true,
            },   
            "field12":{
                "name":"Total Money",
                "field":"event_money",
                "type":"Display",
                "list":true,
            },            
        }
    }
}